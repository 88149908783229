.login-section {
  display: flex;
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

.login-left-design {
  flex: 7;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px; 
  background-color: #ffffff;
  border-radius: 30px;
}

.login-left-design img {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  object-fit: cover;
  animation: login-fadeIn 1.5s ease-in-out; /* Fade-in animation */
}

.login-right-design-container {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 100%;
  animation: login-slideIn 1.5s ease-in-out; /* Slide-in animation */
}

.login-lang-settings {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: gray;
  font-size: 1.1rem;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 0 !important;
}

.ui.dropdown {
  display: flex !important;
  align-items: center !important;
}

.ui.dropdown .text {
  display: inline-block;
  vertical-align: middle;
  margin: 0 !important;
  padding: 0 !important;
  background: none !important;
}

.ui.dropdown .menu {
  margin-top: 2px !important;
  right: 0 !important;
  left: auto !important;
}

.login-inputs {
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-size: 1rem;
  text-align: left;
}

.login-rem-forgot {
  margin-top: 1rem;
}

.login-offscreen {
  display: none;
}

.login-errmsg {
  background-color: rgba(247, 152, 152, 0.5);
  padding: 0.1rem;
  color: crimson;
  animation: login-shake 0.5s; /* Shake animation */
}

.login-h2 {
  color: black;
  font-size: 2rem;
  margin-top: 1rem;
  text-wrap: nowrap;
  animation: login-fadeIn 2s ease-in-out; /* Fade-in animation */
}

.login-span, .login-label, .login-remember-me {
  color: gray;
  font-size: 1rem;
}

.login-right-design {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  box-sizing: border-box;
}

.login-rounded-image {
  width: 200px;
  border-radius: 15px;
  margin-bottom: 1rem;
  animation: login-bounce 1.5s ease-in-out 1s forwards; /* Delayed bounce animation */
}

/* Keyframes for animations */
@keyframes login-fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes login-slideIn {
  0% { transform: translateX(100%); }
  100% { transform: translateX(0); }
}

@keyframes login-shake {
  0%, 100% { transform: translateX(0); }
  25%, 75% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
}

@keyframes login-bounce {
  0%, 100%, 20%, 50%, 80% { transform: translateY(0); }
  40% { transform: translateY(-20px); }
  60% { transform: translateY(-10px); }
}
