.white-background{
    width: 100vw;
    height:100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    overflow: auto;
    background-color: white;
    z-index: 2;
    position: relative;
}
.tables-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
}
.output-header{
    margin-top: 1em;
    margin-bottom: 2em;
    display: flex;
    gap: 3em;
    position: sticky;
}

#download-pdf-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3em;
    margin-top: 3em;
    width: 10em;
    height: 3em;

}

.main-page-container {
    position: relative;
  }

/*  #prev-button{
    position: absolute;
    right: 5%;
    top: 5%;
    margin: 0;
}*/

.ui.navbar.menu{

    z-index: 3;
    position: relative;
}

